<template>
  <div class="elv-sources-container">
    <div v-if="showSyncLoading && false" class="elv-sources-sync-container">
      <div v-if="!syncComplete" class="elv-sources-sync-loading">
        <div>
          <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
          <span>{{
            t('report.syncingSources', {
              progress: reportStore.sourceDoingCount,
              total:
                currentProjectSourceSyncList?.list?.length < reportStore.sourceDoingCount
                  ? reportStore.sourceDoingCount
                  : currentProjectSourceSyncList?.list?.length
            })
          }}</span>
        </div>
        <span v-if="syncTimeout">{{ t('message.sourceSyncTimeoutInfo') }}</span>
      </div>
      <div v-else class="elv-sources-sync-success">
        <div class="elv-sources-sync-success__info">
          <SvgIcon name="sources-sync-done" width="18" height="18" />
          <p class="elv-sources-sync-success__info-text">
            {{ t('report.syncTransactionsComplete') }}<span>{{ 12 }}</span
            >{{ t('report.syncTransactionsTotal') }}
          </p>
          <p class="elv-sources-sync-success__info-button" @click="onJumpTransactionsPage">
            {{ t('button.clickView') }}
          </p>
        </div>
        <SvgIcon
          class="elv-sources-sync-success__close"
          name="close"
          width="18"
          height="18"
          @click="checkSyncLoading(false)"
        />
      </div>
    </div>

    <div class="elv-account-mapping-dialog-tabs" @click="onChangeTab($event)">
      <div
        class="elv-account-mapping-dialog-tab__pane"
        :class="{ 'is-active': activeTab === 'TRANSACTION_OR_BALANCE' }"
        aria-controls="TRANSACTION_OR_BALANCE"
      >
        {{ t('title.transactionAndBalance') }}
      </div>
      <div
        class="elv-account-mapping-dialog-tab__pane"
        :class="{ 'is-active': activeTab === 'JOURNAL_IMPORT' }"
        aria-controls="JOURNAL_IMPORT"
      >
        {{ t('title.journalImport') }}
      </div>
      <div
        class="elv-account-mapping-dialog-tab__pane"
        :class="{ 'is-active': activeTab === 'BUSINESS_DATA' }"
        aria-controls="BUSINESS_DATA"
      >
        {{ t('report.businessData') }}
      </div>
    </div>

    <SourcesTable
      ref="sourcesTableRef"
      :tableData="tableData"
      :tableLoading="loading"
      :type="activeTab"
      :sourcesParams="sourcesParams"
      tableHeight="100%"
      @resetList="getSourceList"
      @onChangePage="onChangePage"
      @onChangePageSize="onChangePageSize"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import SourcesTable from './components/SourcesTable.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import { useReportStore } from '@/stores/modules/reports/index'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const reportStore = useReportStore()
const accountStore = useAccountStore()

const loading = ref(true)
const showSyncLoading = ref(false)
const syncComplete = ref(false)
const sourcesTableRef = useTemplateRef('sourcesTableRef')
const activeTab = ref('TRANSACTION_OR_BALANCE')
const sourceListTimer: any = ref(null)
const transactionStatus = ref('FINISHED')
const synchronizationProgress = ref(0) // 同步进度

const sourcesParams = ref({
  page: 1,
  limit: 20
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const tableData = computed(() => {
  switch (activeTab.value) {
    case 'JOURNAL_IMPORT':
      return accountStore.journalSourceList
    case 'BUSINESS_DATA':
      return accountStore.businessDataSourceList
    default:
      return accountStore.sourceList
  }
})

const currentProjectSourceSyncList = computed(() => {
  return reportStore.sourceSyncList.find((item: any) => item.entityId === entityId.value)
})

const syncTimeout = computed(() => {
  if (!showSyncLoading.value && syncComplete.value) return false
  const syncTime = dayjs(currentProjectSourceSyncList.value?.list?.[0]?.createdAt)
  const nowTime = dayjs()
  const diffTime = nowTime.diff(syncTime, 'minute')
  return diffTime > 3
})

/**
 * @description: 切换显示同步提示
 * @param {boolean} state
 */
const checkSyncLoading = (state: boolean) => {
  showSyncLoading.value = state
  syncComplete.value = false
  const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
    return e.entityId === entityId.value
  })
  if (isInSourceSyncList) {
    if (reportStore.sourceDoingCount < 1) {
      reportStore.sourceSyncList.splice(
        reportStore.sourceSyncList.findIndex((item: any) => item.entityId === entityId.value),
        1
      )
    } else {
      reportStore.editSourceSync(entityId.value, false, {})
    }
  }
}

const onJumpTransactionsPage = () => {
  router.push({ name: 'entity-transactions-list' })
}

const onClearSourceData = () => {
  accountStore.sourceList.total = 0
  accountStore.sourceList.list = []
  accountStore.journalSourceList.total = 0
  accountStore.journalSourceList.list = []
  accountStore.businessDataSourceList.total = 0
  accountStore.businessDataSourceList.list = []
}

const getSourceList = async () => {
  try {
    loading.value = true
    switch (activeTab.value) {
      case 'TRANSACTION_OR_BALANCE':
        await accountStore.fetchAccountSourceList(entityId.value, sourcesParams.value)
        break
      case 'JOURNAL_IMPORT':
        await accountStore.fetchJournalSourceList(entityId.value, sourcesParams.value)
        break
      case 'BUSINESS_DATA':
        await accountStore.fetchBusinessDataSourceList(entityId.value, sourcesParams.value)
        break
      default:
        break
    }
    sourcesTableRef.value?.ref?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

const onChangePageSize = (limit: number) => {
  sourcesParams.value.limit = limit
  sourcesParams.value.page = 1
  getSourceList()
}

const onChangePage = (page: number) => {
  sourcesParams.value.page = page
  getSourceList()
}

/**
 * @description: 切换tab
 * @param {Event} e
 */
const onChangeTab = (e: Event) => {
  const { target } = e
  const tab = (target as HTMLElement)?.getAttribute('aria-controls')
  if (tab) {
    activeTab.value = tab
    sourcesParams.value = {
      page: 1,
      limit: 20
    }
    onClearSourceData()
    setTimeout(() => {
      if (!loading.value) {
        getSourceList()
      }
    }, 100)
  }
}

const onStartSync = async (onLoad: boolean = true) => {
  const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
    return e.entityId === entityId.value
  })
  showSyncLoading.value = true && isInSourceSyncList?.status
  if (entityStore.intervalTimer === null && accountStore.sourceList?.list.length) {
    if (onLoad) {
      await entityStore.fetchEntityDetail(entityId.value)
    }
    syncComplete.value = entityStore.entityDetail?.progress.transactionStatus === 'FINISHED'
    if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
      synchronizationProgress.value = reportStore.sourceDoingCount
      // getSourceList()
    }
    clearInterval(entityStore.intervalTimer)
    entityStore.intervalTimer = setInterval(async () => {
      await entityStore.fetchEntityDetail(entityId.value)
      if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
        synchronizationProgress.value = reportStore.sourceDoingCount
        // getSourceList()
      }
      syncComplete.value = entityStore.entityDetail?.progress.transactionStatus === 'FINISHED'
      if (entityStore.entityDetail?.progress.transactionStatus === 'FINISHED') {
        if (entityStore.intervalTimer !== null) {
          clearInterval(entityStore.intervalTimer)
          entityStore.intervalTimer = null
        }
        synchronizationProgress.value = 0
        // setTimeout(() => {
        //   if (accountStore.sourceList?.list.length) {
        //     reportStore.editSourceSync(entityId.value, true, {}, currentProjectSourceSyncTotal.value)
        //   }
        // }, 1000)
        await entityStore.fetchEntityDetail(entityId.value)
      }
    }, 5000)
  } else {
    if (accountStore.sourceList?.list.length) {
      await entityStore.fetchEntityDetail(entityId.value)
    }
    if (synchronizationProgress.value !== reportStore.sourceDoingCount) {
      synchronizationProgress.value = reportStore.sourceDoingCount
      // getSourceList()
    }
  }
}

emitter.on('onStartSync', onStartSync)

const onFetchIntervalSourceList = () => {
  if (sourceListTimer.value === null) {
    sourceListTimer.value = setInterval(async () => {
      await accountStore.fetchAccountSourceList(entityId.value, sourcesParams.value)
      const isDeleting = accountStore.sourceList?.list.some((item: any) => {
        return item.syncStatus === 'DELETING'
      })
      if (!isDeleting) {
        entityStore.fetchCurrencyList(entityId.value)
      }
    }, 8000)
  }
}
emitter.on('onFetchIntervalSourceList', onFetchIntervalSourceList)

watch(
  () => entityStore.entityDetail,
  async (newValue, oldValue) => {
    if (newValue.entityId !== oldValue?.entityId) {
      transactionStatus.value = entityStore.entityDetail?.progress.transactionStatus ?? 'FINISHED'
    }
    if (transactionStatus.value === 'DOING' && entityStore.entityDetail?.progress.transactionStatus === 'FINISHED') {
      ElMessage.success(t('message.sourceSynchronizationIsCompleted'))
      sourcesParams.value.page = 1
      getSourceList()
    }
    transactionStatus.value = entityStore.entityDetail?.progress.transactionStatus ?? 'FINISHED'
  },
  { immediate: true, deep: true }
)

watch(
  () => route.query,
  async () => {
    if (route.name === 'entity-accounts-sources') {
      try {
        activeTab.value = 'TRANSACTION_OR_BALANCE'
        onClearSourceData()
        await getSourceList()
        // await entityStore.fetchEntityDetail(entityId.value)
        showSyncLoading.value = entityStore.entityDetail?.progress.transactionStatus === 'DOING'
        // synchronizationProgress.value = reportStore.sourceDoingCount
        loading.value = false
        if (entityStore.entityDetail?.progress.transactionStatus === 'DOING') {
          // onStartSync(false)
        } else {
          const isInSourceSyncList = reportStore.sourceSyncList.find((e: any) => {
            return e.entityId === entityId.value
          })
          if (isInSourceSyncList && isInSourceSyncList?.status) {
            showSyncLoading.value = true
            syncComplete.value = true
          }
        }
        // const isDeleting = accountStore.sourceList?.list.some((item: any) => {
        //   return item.syncStatus === 'DELETING'
        // })
        // if (isDeleting) {
        //   onFetchIntervalSourceList()
        // }
      } catch (error) {
        loading.value = false
        console.log(error)
      }
    }
  },
  { immediate: true }
)

onBeforeUnmount(() => {
  emitter.off('onStartSync', onStartSync)
  emitter.off('onFetchIntervalSourceList', onFetchIntervalSourceList)
  clearInterval(sourceListTimer.value)
  sourceListTimer.value = null
  onClearSourceData()
})
</script>

<style lang="scss" scoped>
.elv-sources-container {
  height: calc(100% - 100px);
  position: relative;
}

.elv-sources-sync-container {
  display: flex;
  align-items: center;
  padding: 10px 8px;
  width: 870px;
  height: 38px;
  box-sizing: border-box;
  background: #eef4fb;
  border: 1px solid #dae1e8;
  border-radius: 2px;
  margin-top: 16px;
  margin-left: 20px;

  .elv-sources-sync-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        animation: loading-rotate 2s linear infinite;
      }

      span {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #1e2024;
      }
    }

    span {
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      color: #636b75;
    }
  }

  .elv-sources-sync-success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .elv-sources-sync-success__info {
      display: flex;
      align-items: center;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #1e2024;

      .elv-sources-sync-success__info-text {
        margin: 0;
        margin-left: 8px;

        span {
          font-weight: 700;
          color: #1e2024;
          text-decoration: none;
          cursor: default;
        }
      }

      .elv-sources-sync-success__info-button {
        color: #1753eb;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .elv-sources-sync-success__close {
      cursor: pointer;
    }
  }
}

.elv-account-mapping-dialog-tabs {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
  margin-top: 9px;
  margin-bottom: 10px;
  position: relative;

  .elv-account-mapping-dialog-tab__pane {
    display: flex;
    height: 27px;
    padding: 0px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #e5edff;
    background: #fff;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.1s;
    white-space: nowrap;

    &:not(.is-active):hover {
      border: 1px solid #5e85eb;
    }

    &.is-active {
      background: #1753eb;
      color: #fff;
      border: 1px solid #1753eb;
    }
  }
}
@keyframes loading-rotate {
  to {
    transform: rotate(360deg);
  }
}

.elv-sources-table-wrapper-container {
  height: calc(100% - 86px);
  width: 100%;
  position: relative;
}
</style>
