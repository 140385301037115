<template>
  <div class="elv-sources-table-wrapper-container">
    <el-table
      ref="tableListRef"
      v-table-skeleton="{ loading: props.tableLoading }"
      :data="props.tableData?.list"
      border
      stripe
      :height="props.tableHeight"
      class="elv-sources-table"
      header-cell-class-name="elv-sources-table-header__cell"
      header-row-class-name="elv-sources-table-header"
      row-class-name="elv-sources-table-row"
      cell-class-name="elv-sources-table-row__cell"
    >
      <el-table-column type="index" width="34" class-name="elv-sources-table-row__cell-index">
        <template #default="{ $index }">
          <p class="elv-sources-table-row__cell-index">
            {{ $index + 1 + (props.sourcesParams.page - 1) * props.sourcesParams.limit }}
          </p>
        </template>
      </el-table-column>

      <el-table-column width="260" :label="t('title.sourceName')">
        <template #default="{ row }">
          {{ row.name }}
        </template>
      </el-table-column>
      <template v-if="props.type === 'TRANSACTION_OR_BALANCE'">
        <el-table-column :label="t('common.account')" width="200" class-name="elv-sources-table-row__cell-account">
          <template #default="{ row }">
            <div>
              <img
                v-if="row?.entityAccount?.platform?.logo"
                :src="row?.entityAccount?.platform?.logo"
                :alt="row?.entityAccount?.platform?.name"
                :onerror="useDefaultImage"
              />
              <img v-else src="@/assets/img/default-logo.png" alt="default-logo" :onerror="useDefaultImage" />
              <p>{{ row?.entityAccount?.name }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('title.sourceIntegration')" width="140">
          <template #default="{ row }">
            {{ rowIntegration(row) }}
          </template>
        </el-table-column>
        <el-table-column :label="t('title.sourceData')" width="140">
          <template #default="{ row }">
            {{ rowIntegrationData(row) }}
          </template>
        </el-table-column>
        <el-table-column :label="t('common.updateMethod')" width="170">
          <template #default="{ row }">
            <div class="elv-sources-table-row__cell-method">
              <template v-if="row?.type === 'OPENAPI'">
                <SvgIcon name="API" width="14" height="14" fill="#2F63EB" />
                <span>OpenAPI</span>
              </template>
              <template v-else>
                <SvgIcon
                  :name="['CSV', 'CSV_BALANCE'].includes(row.type) ? 'account-sources-mannual' : 'account-sources-auto'"
                  width="14"
                  height="14"
                />
                <span>{{
                  ['CSV', 'CSV_BALANCE'].includes(row.type)
                    ? t('common.mannual')
                    : `${t('common.auto')} | ${t('common.daily')}`
                }}</span>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('report.added')" width="170">
          <template #default="{ row }">
            <p class="elv-sources-table-row__cell-time">
              {{ dayjs.tz(row.createdAt, row?.timezone ?? 'UTC').format('YYYY/MM/DD HH:mm') }}
            </p>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.updatedAt')" width="170" class-name="elv-sources-table-row__cell-updated">
          <template #default="{ row }">
            <template
              v-if="
                ['HISTORY_BRONEZE_DOING', 'HISTORY_SILVER_DOING', 'UPDATEING', 'DELETEING'].includes(row.syncStatus)
              "
            >
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
              <p>
                {{
                  row.syncStatus === 'DELETEING'
                    ? t('report.deleting')
                    : row?.syncStatus === 'UPDATEING'
                      ? t('common.updating')
                      : t('common.syncing')
                }}
              </p>
            </template>

            <template v-else-if="['FAILED', 'INVALID'].includes(row.syncStatus)">
              <SvgIcon name="sources-sync-error" width="14" height="14" />
              <p>{{ t('common.syncFailed') }}</p>
            </template>
            <template v-else>
              <p class="elv-sources-table-row__cell-time">
                {{
                  row?.syncStatus ? dayjs.tz(row?.updatedAt, row?.timezone ?? 'UTC').format('YYYY/MM/DD HH:mm') : '-'
                }}
              </p>
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="t('common.period')" min-width="170">
          <template #default="{ row }">
            <p class="elv-sources-table-row__cell-time">
              {{ row.transactionOption?.operator ? periodData(row.transactionOption) : '-' }}
            </p>
          </template>
        </el-table-column>
      </template>
      <el-table-column v-if="props.type === 'BUSINESS_DATA'" :label="t('common.integration')" width="140">
        <template #default="{ row }">
          {{ row?.businessDataType?.name ?? '-' }}
        </template>
      </el-table-column>
      <template v-if="['JOURNAL_IMPORT', 'BUSINESS_DATA'].includes(props.type)">
        <el-table-column
          :label="t('common.originalData')"
          width="140"
          :class-name="`elv-sources-table-row__cell-original ${props.type === 'BUSINESS_DATA' ? 'is-underline' : ''}`"
        >
          <template #default="{ row }">
            <div
              :class="{ 'is-empty': isNil(row?.businessDataFile?.count) && props.type === 'BUSINESS_DATA' }"
              @click="onViewOriginalData(row)"
            >
              {{ row?.businessDataFile?.count ?? row.totalCount ?? '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="t('title.importedJournals')"
          width="140"
          class-name="elv-sources-table-row__cell-original is-underline"
        >
          <template #default="{ row }">
            <div @click="onFilterJournalSource(row)">
              {{ row?.identifiedJournalCount ?? row?.recognizedCount ?? '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="t('title.importMethod')" width="140">
          <template #default="{ row }">
            {{ row?.method === 'CSV' || row?.type === 'CSV' ? 'CSV' : 'OpenAPI' }}
          </template>
        </el-table-column>
        <el-table-column :label="t('title.originalFilename')" min-width="140" max-width="16git0" show-overflow-tooltip>
          <template #default="{ row }">
            {{ row?.entityFile?.name || row?.businessDataFile?.originalName || '-' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="props.type === 'JOURNAL_IMPORT'"
          :label="t('title.importedBy')"
          width="140"
          show-overflow-tooltip
        >
          <template #default="{ row }">
            {{ row?.user?.name || '-' }}
          </template>
        </el-table-column>
        <el-table-column :label="t('title.imported')" min-width="120" class-name="elv-sources-table-row__cell-updated">
          <template #default="{ row }">
            <template
              v-if="
                row?.syncStatus === 'DOING' ||
                row?.syncStatus === 'DELETEING' ||
                row?.status === 'DOING' ||
                row?.status === 'DELETEING'
              "
            >
              <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
              <p>
                {{
                  row?.syncStatus === 'DELETEING' || row?.status === 'DELETEING'
                    ? t('report.deleting')
                    : t('common.syncing')
                }}
              </p>
            </template>

            <template
              v-else-if="['FAILED', 'INVALID'].includes(row.syncStatus) || ['FAILED', 'INVALID'].includes(row.status)"
            >
              <SvgIcon name="sources-sync-error" width="14" height="14" />
              <p>{{ t('common.syncFailed') }}</p>
            </template>
            <template v-else>
              <p class="elv-sources-table-row__cell-time">
                {{
                  row?.syncStatus || row?.status
                    ? dayjs
                        .tz(props.type === 'JOURNAL_IMPORT' ? row?.updatedAt : row?.createdAt, row?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm')
                    : '-'
                }}
              </p>
            </template>
          </template>
        </el-table-column>
      </template>

      <el-table-column label="" :width="props.type === 'TRANSACTION_OR_BALANCE' ? 80 : 206" fixed="right">
        <template #default="{ row }">
          <div class="elv-sources-table-row__cell-button">
            <SvgIcon
              v-if="!['CSV', 'CSV_BALANCE'].includes(row.type) && props.type !== 'BUSINESS_DATA'"
              name="sources-edit"
              width="18"
              height="18"
              @click="onEditSource(row)"
            />
            <SvgIcon
              :class="{ 'is-disabled': row?.syncStatus === 'DELETEING' }"
              name="sources-delete"
              width="18"
              height="18"
              @click="onDeleteSource(row)"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      size-popper-class="elv-sources-pagination__sizes-popper"
      :limit="props.sourcesParams.limit"
      :current-page="props.sourcesParams.page"
      :total="props.tableData.total"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />

    <DeleteSource
      ref="deleteSourceRef"
      type="Sources"
      :tableType="props.type"
      :itemData="currentItemData"
      @onUpdateSourceList="emit('onChangePage', 1)"
    />

    <SourceDialog
      ref="editSourceDialogRef"
      :type="currentItemData?.platform?.type ?? props.type"
      model="edit"
      :current-data="currentItemData"
      :thirdPartyOpenAuthTypeData="{}"
      @updateAccountDetail="onUpdateSourceList"
    />

    <OriginBusinessDataDialog v-model="showOriginBusinessDataDialog" :currentData="businessDataCurrentData" />

    <DeleteBusinessDataDialog
      ref="deleteBusinessDataDialogRef"
      type="SOURCE"
      :currentData="businessDataCurrentData"
      @onResetData="emit('resetList')"
    />
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import defaultImg from '@/assets/img/default-logo.png'
import { useEntityStore } from '@/stores/modules/entity'
import SourceDialog from '../../components/SourceDialog.vue'
import DeleteSource from '../../components/DeleteSource.vue'
import { BusinessDataSourceListType } from '#/BusinessDataTypes'
import { isNil, capitalize, isEmpty, cloneDeep } from 'lodash-es'
import { useTransactionStore } from '@/stores/modules/transactions'
import { SourceListType, JournalSourceListType } from '#/AccountsTypes'
import OriginBusinessDataDialog from '../../../components/BusinessData/OriginBusinessDataDialog.vue'
import DeleteBusinessDataDialog from '../../../components/BusinessData/DeleteBusinessDataDialog.vue'

const props = defineProps({
  tableData: {
    type: Object as () => SourceListType | JournalSourceListType | BusinessDataSourceListType,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  sourcesParams: {
    type: Object,
    required: true
  },
  type: {
    type: String, // TRANSACTION_OR_BALANCE | JOURNAL_IMPORT | BUSINESS_DATA
    required: true
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()
const emit = defineEmits(['resetList', 'onChangePage', 'onChangePageSize'])

const tableListRef = useTemplateRef('tableListRef')
const deleteSourceRef = useTemplateRef('deleteSourceRef')
const editSourceDialogRef = useTemplateRef('editSourceDialogRef')
const deleteBusinessDataDialogRef = useTemplateRef('deleteBusinessDataDialogRef')
const businessDataCurrentData: any = ref({})
const showOriginBusinessDataDialog = ref(false)
const currentItemData: any = ref({})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const rowIntegration = computed(() => {
  return (row: any) => {
    if (row.type === 'OPENAPI') return 'OpenAPI'
    return row.type === 'CSV_BALANCE' ? 'CSV' : row.provider?.name
  }
})

const rowIntegrationData = computed(() => {
  return (row: any) => {
    if (row?.provide?.withBalance && row?.provider?.withTransaction) {
      return 'Transaction and Balance'
    }

    if (row?.provider?.withBalance || row?.type === 'CSV_BALANCE') {
      return 'Balance'
    }

    if (row?.provider?.withTransaction) {
      return 'Transaction'
    }
    if (row?.type === 'OPENAPI') {
      return 'Transaction'
    }
    return ''
  }
})

const periodData = computed(() => {
  return (period: any) => {
    let value = ''
    switch (period.operator) {
      case 'BETWEEN':
        value = `${dayjs(period.value[0]).format('YYYY/MM/DD')}-${dayjs(period.value[1]).format('YYYY/MM/DD')}`
        break
      case 'ALL':
        value = '-'
        break
      default:
        value = `${capitalize(period.operator)} - ${dayjs(period.value[0]).format('YYYY/MM/DD')}`
        break
    }
    return value
  }
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

/**
 * @description: 更新来源列表
 */
const onUpdateSourceList = () => {
  editSourceDialogRef.value?.onCheckSourceDialog()
  emit('resetList')
}

/**
 * @description: 查看原始数据
 * @param {*} row
 */
const onViewOriginalData = (row: any) => {
  if (!isNil(row?.businessDataFile?.count) && props.type === 'BUSINESS_DATA') {
    businessDataCurrentData.value = row
    businessDataCurrentData.value.columns = row?.businessDataFile?.columns
    showOriginBusinessDataDialog.value = true
  }
}

/**
 * @description: 跳转Journal页筛选来源
 * @param {*} row
 */
const onFilterJournalSource = (row: any) => {
  if (isNil(row?.identifiedJournalCount) && isNil(row?.recognizedCount)) return
  let filterData: any = {}
  const entityJournalListFilter = transactionStore.entityJournalFilterList.find((item: any) => {
    return item.entityId === entityId.value
  })
  if (!isEmpty(entityJournalListFilter)) {
    filterData = cloneDeep(entityJournalListFilter.data)
    delete filterData.source
    delete filterData.isManual
    delete filterData.sourceId
    delete filterData.businessDataSourceId
    delete filterData.externalJournalSourceId
  }
  if (props.type === 'JOURNAL_IMPORT') {
    filterData.source = row.externalJournalSourceId
    filterData.externalJournalSourceId = row.externalJournalSourceId
  } else {
    filterData.source = row.businessDataSourceId
    filterData.businessDataSourceId = row.businessDataSourceId
  }
  transactionStore.editEntityJournalFilter(entityId.value, filterData)
  router.push({ name: 'entity-ledger-journals' })
}

/**
 * @description: 编辑来源
 * @param {*} row
 */
const onEditSource = async (row: any) => {
  try {
    if (
      ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
      !currentEntityPermission.value?.dataSource?.update
    ) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    currentItemData.value = row
    if (props.type === 'TRANSACTION_OR_BALANCE') {
      currentItemData.value.entityAccountId = row.entityAccount.entityAccountId
      currentItemData.value.platform = row.entityAccount.platform
    }
    editSourceDialogRef.value?.onCheckSourceDialog()
  } catch (error) {
    console.error(error)
  }
}

/**
 * @description: 删除来源
 * @param {*} row
 */
const onDeleteSource = (row: any) => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.dataSource?.delete
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (row?.syncStatus === 'DELETEING') {
    return
  }
  if (props.type === 'BUSINESS_DATA') {
    businessDataCurrentData.value = row
    deleteBusinessDataDialogRef.value?.onCheckDeleteDialog()
  } else {
    currentItemData.value = row
    deleteSourceRef.value?.onCheckDeleteDialog()
  }
}

/**
 * @description: 分页器改变页码触发
 * @param {number} page 当前页码
 */
const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

/**
 * @description: 分页器改变每页条数触发
 * @param {number} limit
 */
const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

/**
 * @description: 图片加载失败触发
 * @param {*} event
 */
const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

defineExpose({
  ref: tableListRef
})
</script>

<style lang="scss">
.elv-sources-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-fill-color-lighter: #f9fafb;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  &.el-table.is-scrolling-none th.el-table-fixed-column--right {
    background: #eef4fb;
  }

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  &.is-scrolling-right .elv-sources-table-header th.el-table-fixed-column--right {
    background: #eef4fb;
  }

  .elv-sources-table-header {
    background: #eef4fb;

    .elv-sources-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &:last-child {
        border-right: 0px;
      }

      &.elv-sources-table-row__amount .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-sources-table-row__cell {
    padding: 0;
    height: 44px;

    &:last-child {
      border-right: 0px;
    }

    &.elv-sources-table-row__cell-index .cell {
      padding: 0px;

      p {
        font-weight: 500;
        width: 100%;
        text-align: center;
        white-space: nowrap;
      }
    }

    &.elv-sources-table-row__cell-account .cell {
      padding-right: 0px;
      display: flex;
      align-items: center;
      overflow: hidden;

      div {
        display: flex;
        align-items: center;
        height: 23px;
        box-sizing: border-box;
        padding: 4px 12px 4px 6px;
        border-radius: 12px;
        border: 1px solid #edf0f3;
        background: #f9fafb;
        margin-right: 4px;

        &:last-child {
          margin-right: 0px;
        }
      }

      img {
        width: 14px;
        height: 14px;
        display: block;
        border-radius: 50%;
        margin-right: 4px;
      }

      p {
        // 不换行
        white-space: nowrap;
      }
    }

    &.elv-sources-table-row__cell-updated .cell {
      display: flex;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      img {
        display: block;
        margin-right: 4px;
        width: 14px;
        height: 14px;
        animation: loading-rotate 2s linear infinite;
      }
    }

    &.elv-sources-table-row__cell-original {
      &.is-underline .cell div {
        width: fit-content;
        text-decoration-line: underline;
        cursor: pointer;

        &.is-empty {
          cursor: default;
          text-decoration-line: none;
        }
      }
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
      white-space: nowrap;

      .elv-sources-table-row__cell-time {
        font-family: 'Barlow';
      }

      .elv-sources-table-row__cell-method {
        display: flex;
        align-items: center;

        svg {
          margin-right: 8px;
        }
      }

      .elv-sources-table-row__cell-button {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          cursor: pointer;
          fill: #838d95;

          &.is-disabled {
            cursor: not-allowed !important;
            fill: #c4c9d1;
          }

          &:first-of-type {
            margin-right: 10px;
          }

          &:not(.is-disabled):hover {
            fill: #1e2024;
          }
        }
      }
    }

    .elv-sources-table-row__cell-activity {
      font-weight: 500;
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.elv-sources-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
